import { useEffect, useState, useCallback, useMemo, FC } from 'react'
import { Card, ExternalLink } from '@app/components'
import { useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { CheckControl, RadioControl } from '@app/components/forms'
import { CaseEditFormFields, CaseFormFields, STLIdentifiers } from '../types'
import {
  CaseArcadeType,
  CasePrintingType,
  CaseType,
} from '@app/services/cases/types'
import { CaseFile } from '@app/services/files/types'
import { mapEnumIntoIterableTypeValue } from '@app/utils'
import { useParams } from 'react-router-dom'
import { LanguagesApp } from '@i18n/types'
import { useHandleUpload } from '@app/hooks/files/useHandleUpload'
import filesService from '@app/services/files/files.service'
import { groupBy } from '@app/utils/groupby'
import { CaseMediaPreview } from '@app/components/media-preview/CaseMediaPreview'
import { useHandleDelete } from '@hooks/files/useHandleDelete.ts'

const printingTypeOptions = mapEnumIntoIterableTypeValue(
  CasePrintingType,
  'cases.measures.printing',
)

type MeasuresFormProps = {
  caseType: CaseType
}

export const MeasuresForm: FC<MeasuresFormProps> = ({ caseType }) => {
  const { caseId } = useParams()
  const { t, i18n } = useTranslation()
  const { watch, setValue, getValues } = useFormContext<CaseEditFormFields>()
  const { handleUpload } = useHandleUpload(caseId!)
  const { handleDelete } = useHandleDelete(caseId!)
  const [conditions, setConditions] = useState<string | undefined>(undefined)

  const [files, setFiles] = useState<CaseFormFields['files']>(
    getValues('files'),
  )

  const formatWatch = watch('measurement.method')
  const archTreatWatch = watch('general.archTreat')

  useEffect(() => {
    setValue('measurement.method', CasePrintingType.Scanner)
  }, [setValue])

  useEffect(() => {
    ;(Object.keys(files) as Array<keyof typeof files>).forEach(key => {
      const fileList = files[key]
      const fieldName =
        `measurement.files.${key}.file` as keyof CaseEditFormFields
      if (fileList && fileList.length > 0) {
        setValue(fieldName, true, { shouldValidate: false })
      } else {
        setValue(fieldName, false, { shouldValidate: false })
      }
    })
  }, [files, setValue])

  const getConditionFromLocale = async (locale: string) => {
    switch (locale) {
      case LanguagesApp.EN:
        return (
          await import(
            '@assets/conditions/en_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      case LanguagesApp.ES:
        return (
          await import(
            '@assets/conditions/es_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      case LanguagesApp.DE:
        return (
          await import(
            '@assets/conditions/de_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      case LanguagesApp.PT:
        return (
          await import(
            '@assets/conditions/pt_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      case LanguagesApp.IT:
        return (
          await import(
            '@assets/conditions/it_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      case LanguagesApp.FR:
        return (
          await import(
            '@assets/conditions/fr_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
      default:
        return (
          await import(
            '@assets/conditions/en_Terms_of_Use_and_Purchase_090724.pdf'
          )
        ).default
    }
  }

  useEffect(() => {
    const getConditions = async (lang: string) => {
      const result = await getConditionFromLocale(lang)
      setConditions(result)
    }

    getConditions(i18n.language)
  }, [i18n.language])

  const acceptConditions = useMemo(
    () => (
      <Trans
        i18nKey={t('cases.measures.accept')}
        components={[<ExternalLink key="" href={conditions} />]}
      />
    ),
    [conditions, t],
  )

  const hasSelectArch = useCallback(
    (arch: CaseArcadeType) => {
      if (archTreatWatch === CaseArcadeType.Both || archTreatWatch === arch) {
        return true
      }
      return false
    },
    [archTreatWatch],
  )

  type MeasurementFileFieldName =
    | 'measurement.files.intraoral-scannings-upper.file'
    | 'measurement.files.intraoral-scannings-lower.file'
    | 'measurement.files.intraoral-scannings-occlusal.file'

  const onFileChange =
    (formName: MeasurementFileFieldName) => async (uploadFiles?: File[]) => {
      await handleUpload(formName, uploadFiles)
      setFiles(groupBy(await filesService.getCaseFiles(caseId!), 'identifier'))

      const hasFiles = !!(uploadFiles && uploadFiles.length > 0)
      setValue(formName, hasFiles, { shouldValidate: false })
    }

  const onFileDelete = async (file: CaseFile) => {
    await handleDelete([file])
    setFiles(groupBy(await filesService.getCaseFiles(caseId!), 'identifier'))

    const formName =
      `measurement.files.${file.identifier}.file` as MeasurementFileFieldName
    setValue(formName, false, { shouldValidate: false })
  }

  return (
    <Card.Body className="MeasuresForm" size={'sm'}>
      <h3 className="form-subtitle">{t('cases.measures.format.title')}</h3>
      <p>{t('cases.measures.format.body.common')}</p>

      {caseType !== CaseType.SecretRetainer && (
        <>
          <p>{t('cases.measures.format.body.method')}</p>

          <div className="mb-4">
            <RadioControl
              direction="col"
              name="measurement.method"
              label={t('cases.measures.format.title')}
              required
              hiddenLabel
              options={printingTypeOptions?.map(d => ({
                label: t(d.type),
                value: d.value,
              }))}
              defaultValue={CasePrintingType.Scanner}
            />
          </div>
          {formatWatch === CasePrintingType.Scanner && (
            <>
              <p>{t('cases.measures.scanner.title')}</p>

              <div className="FilesForm-list">
                {hasSelectArch(CaseArcadeType.Upper) && (
                  <div className="FilesForm-item" key={CaseArcadeType.Upper}>
                    <CaseMediaPreview
                      key="intraoral-scannings-upper"
                      name={
                        'measurement.files.intraoral-scannings-upper.file' as keyof CaseEditFormFields
                      }
                      label={t('cases.measures.scanner.upper')}
                      onChange={onFileChange(
                        'measurement.files.intraoral-scannings-upper.file',
                      )}
                      identifier={STLIdentifiers.Upper}
                      files={files?.[STLIdentifiers.Upper] ?? []}
                      accept=".stl,.zip"
                      handleDelete={onFileDelete}
                      required={true}
                    />
                  </div>
                )}

                {hasSelectArch(CaseArcadeType.Lower) && (
                  <div className="FilesForm-item" key={CaseArcadeType.Lower}>
                    <CaseMediaPreview
                      key="intraoral-scannings-lower"
                      name={
                        'measurement.files.intraoral-scannings-lower.file' as keyof CaseEditFormFields
                      }
                      label={t('cases.measures.scanner.lower')}
                      onChange={onFileChange(
                        'measurement.files.intraoral-scannings-lower.file',
                      )}
                      identifier={STLIdentifiers.Lower}
                      files={files?.[STLIdentifiers.Lower] ?? []}
                      accept=".stl,.zip"
                      handleDelete={onFileDelete}
                      required={true}
                    />
                  </div>
                )}

                {(hasSelectArch(CaseArcadeType.Upper) ||
                  hasSelectArch(CaseArcadeType.Lower)) && (
                  <div className="FilesForm-item" key={'occlusal'}>
                    <CaseMediaPreview
                      key="intraoral-scannings-occlusal"
                      name={
                        'measurement.files.intraoral-scannings-occlusal.file' as keyof CaseEditFormFields
                      }
                      label={t('cases.measures.scanner.occlusal')}
                      onChange={onFileChange(
                        'measurement.files.intraoral-scannings-occlusal.file',
                      )}
                      identifier={STLIdentifiers.Occlusal}
                      files={files?.[STLIdentifiers.Occlusal] ?? []}
                      accept=".stl,.zip"
                      handleDelete={onFileDelete}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      <CheckControl<CaseFormFields>
        name={'accept'}
        label={acceptConditions}
        required
      />
    </Card.Body>
  )
}
