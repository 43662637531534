import { useAppDispatch } from '@app/store/hooks'
import {
  hideLoader,
  setUserDetail,
  setUserToken,
  showLoader,
} from '@app/store/slices'
import {
  setIsLoggingIn,
  setIsSessionChecked,
} from '@app/store/slices/authLocalStorage/authLocalStorageSlice'
import { useToast } from '@app/hooks'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import userManager from '../../../config/authConfig'
import profileService from '@app/services/profile/profile.service'
import { DetailUser } from '@app/services/auth/types'
import throttle from 'lodash.throttle'
import { useSpinner } from '@app/context/SpinnerProvider.tsx'

const VITE_LEGACY_URL = import.meta.env.VITE_LEGACY_URL

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const { toastError } = useToast()
  const { t } = useTranslation()
  const { setShowSpinner } = useSpinner()

  const login = useCallback(
    throttle(async () => {
      dispatch(setIsLoggingIn(true))

      try {
        await userManager.signinRedirect()
      } catch (error) {
        console.error('Error during signinRedirect:', error)
        toastError(t('login.errors.login'))
        dispatch(setIsLoggingIn(false))
        dispatch(setIsSessionChecked(false))
      }
    }, 300),
    [dispatch, toastError, t],
  )

  const logout = useCallback(
    throttle(async () => {
      console.log('logout')

      setShowSpinner(true)
      dispatch(showLoader())
      try {
        dispatch(setIsSessionChecked(false))
        dispatch(setIsLoggingIn(false))
        dispatch(setUserToken(null))
        dispatch(setUserDetail(null))
        await userManager.removeUser()
        await userManager.signoutRedirect()
      } catch (e) {
        console.error('Error during signoutRedirect:', e)
        toastError(t('login.errors.logout'))
      } finally {
        setShowSpinner(false)
        dispatch(hideLoader())
      }
    }, 300),
    [dispatch, toastError, t, setShowSpinner],
  )

  const checkSession = useCallback(
    throttle(async () => {
      dispatch(setIsSessionChecked(true))
      try {
        const user = await userManager.getUser()
        if (user) {
          dispatch(setUserToken(user.access_token))
          const userProfile = await profileService.getUserProfile()
          dispatch(setUserDetail(userProfile.data as DetailUser))

          if (
            userProfile.data.roles.length !== 1 ||
            !userProfile.data.roles.includes('ROLE_USER')
          ) {
            setShowSpinner(true)
            window.location.href = VITE_LEGACY_URL
          }
        } else {
          await login()
        }
      } catch (e) {
        dispatch(setUserToken(null))
        dispatch(setUserDetail(null))
        await login()
      } finally {
        dispatch(setIsLoggingIn(false))
      }
    }, 100),
    [dispatch, login, setShowSpinner],
  )

  useEffect(() => {
    throttle(() => checkSession(), 100)
  }, [checkSession])

  return {
    login,
    logout,
    checkSession,
  }
}
