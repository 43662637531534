import { useAuth } from '@app/hooks'
import { AxiosError } from 'axios'
import { useEffect } from 'react'

import http from '@app/core/http/http-client'
import { useNavigate } from 'react-router-dom'

export const ResponseInterceptor = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    http.provider.interceptors.response.use(undefined, (error: AxiosError) => {
      if (error.response?.status === 401) {
        navigate('/logout')
        return
      }

      return Promise.reject(error)
    })
  }, [logout, navigate])

  return null
}
