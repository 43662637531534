import { useEffect, useRef } from 'react'
import { MediaPreview } from './MediaPreview'
import { CaseFile } from '@app/services/files/types'
import { useFormContext, useController } from 'react-hook-form'
import { FormControlWrapper } from '@components/forms/form-control-wrapper/FormControlWrapper.tsx'

type CaseMediaPreviewProps = {
  identifier: string
  name: string
  id?: string
  label: string
  files: CaseFile[]
  onChange?: (files?: File[]) => Promise<void>
  onImageClick?: (file: CaseFile) => void
  handleDelete?: (file: CaseFile) => Promise<void>
  disabled?: boolean
  accept?: string
  required?: boolean
}

export const CaseMediaPreview = function (
  props: CaseMediaPreviewProps,
): JSX.Element {
  const {
    identifier,
    name,
    id,
    label,
    onChange,
    onImageClick,
    handleDelete,
    disabled,
    accept,
    files,
    required,
  } = props

  const inputFileRef = useRef<HTMLInputElement | null>(null)
  const uploadPromiseRef = useRef<(() => void) | null>(null)

  const { control, setValue } = useFormContext()

  const {
    field,
    fieldState: { error: fieldError },
  } = useController({
    name,
    control,
  })

  const prefixedId = id ?? `media-upload-${name}`

  useEffect(() => {
    if (files.length > 0) {
      const latestFile = files[files.length - 1]
      setValue(name, latestFile.fileName, { shouldValidate: false })
    } else {
      setValue(name, '', { shouldValidate: false })
    }
  }, [files, name, setValue])

  const handleChangeFile = async () => {
    const input = inputFileRef.current
    if (!input) {
      return
    }

    const fileList: FileList = input.files!
    const newFiles = Array.from(fileList)

    if (newFiles.length > 0) {
      if (onChange) {
        await onChange(newFiles)
      }
    }

    input.value = ''

    if (uploadPromiseRef.current) {
      uploadPromiseRef.current()
      uploadPromiseRef.current = null
    }
  }

  const handleClickUpload = () => {
    return new Promise<void>(resolve => {
      uploadPromiseRef.current = resolve
      const input = inputFileRef.current
      if (input) {
        input.click()
      } else {
        resolve()
      }
    })
  }

  const handleClear = async () => {
    if (onChange) {
      await onChange(undefined)
    }

    field.onChange(undefined)
  }

  return (
    <FormControlWrapper
      error={fieldError}
      name={field.name}
      label={label}
      id={prefixedId}
      required={required}
    >
      <div className="UploadControl">
        <div>
          <input
            className="form-control d-none"
            ref={inputFileRef}
            type="file"
            accept={accept ?? 'image/*,application/zip,.dcm,capture=camera'}
            id={prefixedId}
            disabled={disabled}
            name={field.name}
            onChange={async e => {
              await field.onChange(e)
              await handleChangeFile()
            }}
            onBlur={field.onBlur}
            tabIndex={-1}
            multiple
          />
        </div>

        <MediaPreview
          title={label as string}
          id={prefixedId}
          handleClear={handleClear}
          handleUpload={handleClickUpload}
          identifier={identifier}
          files={files}
          handleClickImage={() =>
            onImageClick && onImageClick(files[files.length - 1])
          }
          handleDelete={handleDelete}
        />
      </div>
    </FormControlWrapper>
  )
}
